<template>
  <div
    v-if="calculatedScore"
    class="tw-mt-0.5 tw-w-6 tw-h-6 tw-bg-success tw-rounded-full tw-flex tw-items-center tw-justify-center"
  >
    <button
      type="button"
      class="tw-text-xs tw-text-white tw-font-bold"
      @click="showContactScoreModal"
    >
      <i v-if="loading" class="fas fa-spinner-third fa-spin" />
      <span v-else>{{ calculatedScore }}</span>
    </button>
    <ContactScoreDetails ref="scoreDetails" @set="setScore" />
  </div>
</template>

<script>
import ContactScoreDetails from '@/components/contacts/ContactScoreDetails.vue'

export default {
  name: 'ContactScore',
  components: {
    ContactScoreDetails
  },
  props: {
    score: {
      type: Number
    },
    contactId: {
      type: [String, Number]
    }
  },
  data () {
    return {
      calculatedScore: null,
      loading: false
    }
  },
  created () {
    this.calculatedScore = this.score
  },
  methods: {
    async showContactScoreModal () {
      this.loading = true
      await this.$refs.scoreDetails.show(this.contactId)
      this.loading = false
    },
    setScore (score) {
      this.calculatedScore = score
    }
  }
}
</script>
