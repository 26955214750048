<template>
  <div class="tw--mx-3 tw-pb-2.5 tw-px-6 tw-font-light tw-flex tw-flex-row tw-flex-wrap tw-gap-x-4 tw-justify-between tw-items-baseline tw-bg-white tw-border-b">
    <h2 :title="title">
      <router-link
        v-if="backRoute"
        :to="backRoute"
        title="Ga terug"
        class="!tw-text-tg-color hover:tw-opacity-80"
      >
        <i class="fal fa-arrow-left tw-mr-2" />
      </router-link>
      <slot>{{ title }}</slot>
    </h2>
    <div class="tw-flex tw-flex-row tw-flex-grow tw-justify-end">
      <slot name="buttons" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'PageHeader',
  props: {
    title: {
      type: String,
      required: true
    },
    backRoute: {
      type: [Object, String],
      default: null
    }
  }
}
</script>
