<template>
  <BaseModal
    ref="modal"
    title="Details score"
    max-width="tw-max-w-3xl"
  >
    <div v-if="values">
      <table class="tw-w-full tw-mb-2">
        <tr class="tw-bg-gray-e9">
          <th class="tw-p-1 tw-border tw-pl-2">Criterium</th>
          <th class="tw-p-1 tw-border tw-pl-2">Waarde</th>
          <th class="tw-p-1 tw-border tw-pl-2">Score</th>
        </tr>
        <tr v-for="question in values.questions_breakdown" :key="question.question">
          <td class="tw-p-1 tw-border tw-pl-2">{{ question.question }}</td>
          <td class="tw-p-1 tw-border tw-pl-2">{{ question.condition || '-' }}</td>
          <td class="tw-p-1 tw-border tw-pl-2">{{ question.score || '-' }}</td>
        </tr>
        <tr class="tw-bg-white tw-font-bold">
          <td class="tw-p-1 tw-border-l tw-border-b tw-pl-2">Totaal</td>
          <td class="tw-p-1 tw-border-b tw-pl-2"></td>
          <td class="tw-p-1 tw-border-b tw-border-r tw-pl-2">{{ values.score }}</td>
        </tr>
      </table>
      <p class="tw-italic tw-mt-4">Type HMODH: {{ getHmodhTypeDisplay(values.field) }}</p>
      <p class="tw-italic">Maximumscore voor dit type HMODH: {{ values.max_score }}</p>
      <p class="tw-italic">Mediaanscore alle contacten: {{ values.median }}</p>
      <p class="tw-italic">Gemiddelde score alle contacten: {{ values.mean }}</p>
    </div>
  </BaseModal>
</template>

<script>
import { calculateContactScore } from '@/services/contacts'

export default {
  name: 'ContactScoreDetails',
  data () {
    return {
      values: null
    }
  },
  methods: {
    async show (contactId) {
      const response = await calculateContactScore(contactId)
      this.$emit('set', response.data?.score)
      if (!response.data?.score) return
      this.values = response.data
      this.$refs.modal.show()
      return response
    },
    getHmodhTypeDisplay (field) {
      const display = {
        residential_sale: 'Woonvastgoed - te koop',
        residential_rental: 'Woonvastgoed - te huur',
        commercial: 'Bedrijfsvastgoed',
        luxury: 'Luxevastgoed',
        newly_built: 'Nieuwbouw',
        invest: 'Investeringsvastgoed'
      }
      return display[field]
    }
  }
}
</script>
